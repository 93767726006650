<template>
  <a-card :bordered='false'>
      <a-row class="query-row" type='flex' :gutter="12">
        <a-col style="width:220px">
           <span>主订单号：</span>
           <a-input v-model="queryData.orderBn" allowClear placeholder='请输入'></a-input>
        </a-col>
        <a-col style="width:220px">
           <span>子订单号：</span>
           <a-input v-model="queryData.orderSn" allowClear placeholder='请输入'></a-input>
        </a-col>
        <a-col style="width:300px">
           <span>支付时间：</span>
           <a-range-picker v-model="payTime" valueFormat='YYYY-MM-DD 00:00:00' />
        </a-col>
        <a-col style="width:200px">
           <span>下单人：</span>
           <a-input v-model="queryData.buyerId" allowClear placeholder='请输入'></a-input>
        </a-col>
        <a-col style="width:200px">
           <span>分享人：</span>
           <a-input v-model="queryData.referrerId" allowClear placeholder='请输入'></a-input>
        </a-col>
         <a-button type='primary' style="margin:0 12px" @click="() => {this.pageNum = 1;this.loadTableData()}" >查询</a-button>
         <a-button type='primary' @click="$router.push({name:'WithdrawnReward'})">撤回订单奖励</a-button>
      </a-row>
      <a-table :columns="columns" :dataSource="tableData" :pagination="false" :rowKey="record => record.orderSn "
             :loading="loading"
             :scroll="{x: true}"
             size="default">
      </a-table>
      <a-pagination
      style="display: flex; flex-direction: row; justify-content: flex-end; margin:10px 10px 5px 0"
      size="small"
      v-model="pageNum"
      :total="total"
      :showTotal="total => `共 ${total} 条`"
      showSizeChanger
      showLessItems
      :pageSize.sync="pageSize"
      @change="loadTableData"
      @showSizeChange="() => {this.pageNum = 1;this.loadTableData()}"
    />

    <a-modal v-model="showModal" :width='660' :footer="null" :maskClosable='false' title="查看">
      <a-table :columns="modalColumns"  :pagination="false" :dataSource="modalTableData"  :rowKey="record => record.userId "
             :loading="loading"
             :scroll="{x: true}"
             size="default">
      </a-table>
    </a-modal>
  </a-card>
</template>

<script>
import api from '@/api'
import moment from 'moment'
export default {
   name:'WithdrawnList',
   data () {
      return {
         pageNum:1,
         pageSize:10,
         total:0,
         showModal:false,
         queryData:{
            orderBn:undefined,
            orderSn:undefined,
            buyerId:undefined,
            referrerId:undefined,
         },
         payTime:[],
         tableData: [],
         modalTableData:[],
         columns:[
            {
               title: '序号',
               align: 'center',
               ellipsis:true,
               customRender: (text, record, index) => {
                  return index + 1
               }
            },
            {
               title: '主订单号',
               dataIndex: 'orderBn',
               align: 'center',
               ellipsis:true
            },
            {
               title: '子订单号',
               dataIndex: 'orderSn',
               align: 'center',
               ellipsis:true
            },
            {
               title: '订单价格',
               dataIndex: 'price',
               align: 'center',
               ellipsis:true
            },
            {
               title: '下单人',
               dataIndex: 'buyerId',
               align: 'center',
               ellipsis:true
            },
            {
               title: '分享人',
               dataIndex: 'referrerId',
               align: 'center',
               ellipsis:true
            },
            {
               title: '是否过售后',
               dataIndex: 'isAftersale',
               align: 'center',
               ellipsis:true,
               customRender: (text,record) => {
                  return ['否','是'][text] || ''
               }
            },
            {
               title: '操作',
               align: 'center',
               ellipsis:true,
               customRender: (text,record) => {
                  return (
                     this.$store.state.permissionList.includes('withdrawnList:detail') ? 
                     <a onClick={() => {this.handleClick(record.voucherId)}}>查看</a> :
                     ''
                  )
               }
            } 
         ],
         modalColumns:[
            {
               title: '受益人',
               dataIndex: 'mobile',
               align: 'center',
               ellipsis:true
            },
            {
               title: '奖励类型',
               dataIndex: 'rewardType',
               align: 'center',
               ellipsis:true,
               customRender: (text, record, index) => {
                  let map = {
                     '1':'推广积分',
                     '2':'激活积分',
                     '3':'奖励积分',
                     '4':'销售奖',
                     '5':'二级社群奖',
                     '6':'三级社群奖',
                     '7':'二级培育奖',
                     '8':'三级培育奖',
                     '9':'小汇豆销售奖',
                     '10':'小汇豆俱乐部补贴',
                     '11':'小汇豆服务商补贴',
                     '12':'小汇豆俱乐部培育奖',
                     '13':'小汇豆服务商培育奖',
                  }
                  return map[text]
               }
            },
            {
               title: '数值',
               dataIndex: 'rewardAmount',
               align: 'center',
               ellipsis:true
            },
            {
               title: '奖励发放状态',
               dataIndex: 'status',
               align: 'center',
               ellipsis:true,
               customRender: (text, record, index) => {
                  let map = {
                     '1':'待发放',
                     '2':'已发放',
                     '3':'已取消',
                     '4':'已冻结',
                     '5':'空号不发',
                     '6':'未激活不发',
                     '9':'扣减成功'
                  }
                  return map[text]
               }
            },
            {
               title: '下发时间',
               dataIndex: 'time',
               align: 'center',
               ellipsis:true,
               customRender: (text, record, index) => {
                  return text ? moment(text * 1000).format('YYYY-MM-DD HH:mm:ss') : '-'
               }
            },
         ],
         loading:false,
      }
   },
   created () {
      this.loadTableData()
   },
   watch: {
      'queryData.referrerId' (val) {
         if (!val) {
            this.queryData.referrerId = undefined
         }
      }
   },
   methods: {
      loadTableData () {
         let params = {...this.queryData,pageNum:this.pageNum,pageSize:this.pageSize}
         if (this.payTime[0] && this.payTime[1]) {
            params.payTimeStart = new Date(this.payTime[0]).getTime() / 1000
            params.payTimeEnd = new Date(this.payTime[1]).getTime() / 1000
         }
         this.loading = true
         api.marketManage.withdrawList(params).then(res => {
            if (res.code === '00000') {
               this.tableData = res.data.dataList
               this.total = res.data.total
            } else {
               this.$message.error(res.msg)
            }
         }).finally(() => this.loading = false)
      },
      handleClick (voucherId) {
         this.showModal = true
         this.loading = true
         api.marketManage.returnList({voucherId}).then(res => {
            if (res.code === '00000') {
               this.modalTableData = res.data.dataList
            } else {
               this.$message.error(res.msg)
            }
         }).finally(() => this.loading = false)
      }
   }
}
</script>

<style>

</style>